@use '@angular/material' as mat;
@import "@angular/material/theming";

@font-face {
  font-family: 'SFPro';
  src: url('assets/fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('assets/fonts/SFProText-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: url('assets/fonts/SFProText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

$light-primary-text: #fff;
$dark-primary-text: #4A494A;
$custom-font-family: '"SFPro", Arial';

$custom-typography: mat.define-typography-config($font-family: $custom-font-family);

$mat-primary: (
  main: #00A82D,
  lighter: #bee7cf,
  darker: #179444,
  50: #e0f7e9,
  100: #b3eacc,
  200: #80dbac,
  300: #00A82D,
  400: #26bd72,
  500: #00A82D,
  600: #009d29,
  700: #008f24,
  800: #00811f,
  900: #006a16,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$mat-accent: (
  main: #2981d3,
  lighter: #bfd9f2,
  darker: #1964c2,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$mat-warn: (
  main: #e74c3c,
  lighter: #ffcdc9,
  darker: #ff3c33,
  contrast: (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: $custom-typography,
));

@include mat.all-component-themes($theme);
@include mat.core();

html, body { height: 100%; }
body { margin: 0; font-family: "SFPro", Roboto, "Helvetica Neue", sans-serif; font-size: 14px; line-height: 16px; }

.mat-mdc-snack-bar-container {

  &.error {

    .mdc-snackbar__surface,
    .mat-mdc-snack-bar-label {
      padding: 0;
      background-color: #fff;
      border-radius: 8px;
    }
  }
}

/* Default light theme styles */
body {
  background-color: #fff;
  color: #000;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

/* Detects if the user has set their system to use a dark theme */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #fff;
  }
}
